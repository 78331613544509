.card {
  background-color: #fff;
  padding: 40px;
  margin-bottom: 20px;
  border-radius: var(--border-radius);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  transition: all 0.25s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.card:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.25s ease-out;
}

.card h1 {
  font-size: 28px;
  font-weight: 500;
}

.card p {
  line-height: 24px;
  width: 100%;
}

.intro-icon {
  width: 60px;
}

.titleBorderTop {
  max-width: 100%;
}
.titleBorderTop h1 {
  margin-top: 10px;
}

.colorRectangle {
  background-color: var(--primary-color);
  height: 15px;
  width: 40px;
}

.listCtn {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 0;
  gap: 22px;
  flex: 0 1 100%;
}

.cardSectionTitle {
  padding-left: 15px;
  border-left: 4px solid var(--primary-color);
  font-size: 18px;
}

.listItem {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 10px 16px;
  border-radius: var(--border-radius);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  font-weight: 500;
  transition: all 0.25s ease-out;
}

.cardsCtn {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
}

.listItemCard {
  flex-direction: column;
  flex: 1;
  min-width: 220px;
}

.listItemCardContent {
  font-size: 14px;
  font-weight: 400;
}

a.listItem {
  cursor: pointer;
  text-decoration: none;
}

.listItem:hover {
  color: var(--primary-color);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  transition: all 0.25s ease-out;
}

.htmlIcon {
  margin-right: 10px;
  min-width: 30px;
  max-width: 30px;
}

.pdfIcon {
  margin-right: 10px;
  min-width: 25px;
}

.contactPageCtn {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.contactCard {
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 600px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
}

.contactListCtn .row {
  justify-content: flex-start;
  gap: 10px;
}

.contactUsIconCtn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactListCtn {
  flex-direction: column;
  flex: 4;
}

.contactItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contactItem .bi {
  font-size: 20px;
  margin-right: 17px;
  color: #b1b1b1;
  transition: all 0.25s ease-out;
}
.contactItem img {
  margin-left: -3px;
  margin-right: 12px;
}

.contactItem {
  text-decoration: none;
}

.contactItem:hover .bi-telephone-fill {
  color: #37816e;
  transition: all 0.25s ease-out;
}

.contactItem:hover .bi-envelope-fill {
  color: #edb723;
  transition: all 0.25s ease-out;
}

.contactItem .weChatIcon {
  filter: grayscale(100%) brightness(128%);
  transition: all 0.25s ease-out;
}

.contactItem:hover .weChatIcon {
  filter: grayscale(0) brightness(100%);
  transition: all 0.25s ease-out;
}

.contactItem:hover .bi-skype {
  color: #1d9bf0;
  transition: all 0.25s ease-out;
}

.contactItem:hover .bi-twitter {
  color: #1d9bf0;
  transition: all 0.25s ease-out;
}

.contactItem:hover .bi-linkedin {
  color: #0a66c2;
  transition: all 0.25s ease-out;
}

.contactAddress {
  width: 100%;
  height: 100%;
  flex: 2;
}

@media (min-width: 800px) {
  .contactCard {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    overflow: hidden;
    padding-top: 0;
  }
  .contactUsIconCtn {
    padding-right: 30px;
  }
  .contactListCtn {
    flex: 2;
  }
}

@media (min-width: 1100px) {
  .titleBorderTop {
    max-width: 350px;
  }
  .listCtn {
    flex: 0 1 60%;
    padding-top: 35px;
  }

  .contactCard {
    width: 80%;
  }

  .contactUsIconCtn {
    padding-right: 60px;
  }
}
